import React from 'react';
import { render } from 'react-dom';

import { Provider } from 'react-redux';
import createStore from 'lib/createStore';
import { ApolloProvider } from 'react-apollo';
import createApolloClient from 'lib/createApolloClient';
import ShareToDriveAsync from 'components/OnlineResource/Partners/Google/ShareToDriveAsync';

const apolloClient = createApolloClient({
  url: '',
  req: {
    headers: {
      cookie: window.document.cookie
    }
  },
  opts: {
    credentials: 'same-origin'
  }
});

const store = createStore(JSON.parse(window.__TPT__.dumpStore()));

/* eslint-disable react/jsx-filename-extension */
function appendAddToGoogleDriveButton(parentSelector = '') {
  const addToDriveNodes = document.querySelectorAll(`${parentSelector} .add_to_drive_container`);
  if (addToDriveNodes.length) {
    for (let i = 0; i < addToDriveNodes.length; i++) {
      const addToDriveNode = addToDriveNodes[i];
      const {
        dataset: { itemid, price, appearance, placement }
      } = addToDriveNode;
      render(
        <ApolloProvider client={apolloClient}>
          <Provider store={store}>
            <ShareToDriveAsync
              itemId={itemid}
              price={parseFloat(price)}
              appearance={appearance}
              placement={placement}
              isLoggedIn
            />
          </Provider>
        </ApolloProvider>,
        addToDriveNode
      );
    }
  }
}

window.__TPT__.appendAddToGoogleDriveButton = appendAddToGoogleDriveButton;

appendAddToGoogleDriveButton();

/* eslint-enable react/jsx-filename-extension */
