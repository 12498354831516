import React from 'react';
import { asyncComponent } from 'react-async-component';
import Button from 'components/LegacyButton';
import Anchor from 'components/Anchor';
import './ShareToDrive.scss';

export default asyncComponent({
  name: 'ShareToDriveAsync',
  serverMode: 'defer',
  /* eslint-disable react/prop-types */
  // eslint-disable-next-line react/display-name
  LoadingComponent: ({ fullWidth = true, appearance = 'button', size = 'md' }) => (
    // eslint-disable-next-line react/prop-types
    <div className="ShareToDrive">
      {appearance === 'link' ? (
        <Anchor to="">Add to Google Drive</Anchor>
      ) : (
        <Button label="Add to Google Drive" fullWidth={fullWidth} disabled size={size} />
      )}
    </div>
    /* eslint-enable react/prop-types */
  ),
  resolve: () => import(/* webpackChunkName: "ShareToDrive" */ './ShareToDrive')
  // eslint-disable-next-line react/display-name
});
